import { Atom, SHARED_VALIDATIONS, helper } from ":mods";

const is_dev = helper.env.isDev;
const mocks = {
  student: {
    email: "abdul.2020alsh@gmail.com",
    password: "Test@332211",
  },
  // eslam on mail.tm, password same as email on mail.tm and moc
  eslam_temp_admin: {
    email: "eslam-temp-admin@hldrive.com",
    password: "Moc@1234",
  },
  eslam_temp_student: {
    email: "eslam-temp-student@hldrive.com",
    password: "Moc@1234",
  },
  eslam_temp_tutor: {
    email: "eslam-temp-tutor@hldrive.com",
    password: "Moc@1234",
    //
  },
};

export function createLoginInputs(mock?: keyof typeof mocks) {
  const m = is_dev && mock ? mocks[mock] : undefined;
  const Email = Atom.Form.createInput(m?.email, {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.email.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Password = Atom.Form.createInput(m?.password, {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.password.validate(value);
      // console.log("validating password", value, " :: ", err);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Actions = Atom.Form.createFormActions({ Email, Password });
  return {
    Actions,
    Email,
    Password,
  };
}
